.buttons {
  display: flex;
  padding: 4px 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 500px;
  background: #ffffff;
  color: #4350af;
  white-space: nowrap;
}
.active {
  background: #4350af;
  color: #ffffff;
}
.filter-button-text {
  white-space: nowrap;
}
.table-headers {
  margin-top: -15px !important; // for keeping icons in line with column header text
}
.header-icons {
  color: #202020;
  font-family: Font Awesome 6 Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  opacity: 0.5400000214576721;
}
.table-filter-container {
  display: flex;
  padding: 32px 16px;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  flex-wrap: wrap;
}
.table-filters {
  display: flex;
  gap: 10px;
  padding: 10px 0px;
}
.textbox-filter {
  display: inline;
  width: 200px;
  overflow-y: auto;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  // top: 50px;
  border-radius: 4px;
  background: #fff;
  box-shadow:
    0px 0px 0px 0px rgba(0, 0, 0, 0.04),
    0px 4px 12px 4px rgba(0, 0, 0, 0.24);
  padding: 10px 5px 5px;
}
.row-filter-container {
  position: relative;
}
.checkbox-filter-container {
  position: absolute;
  display: flex;
  width: 100%;
  max-width: 225px;
  flex-direction: column;
  align-items: flex-start;
  // top: 50px;
  border-radius: 4px;
  background: #fff;
  box-shadow:
    0px 0px 0px 0px rgba(0, 0, 0, 0.04),
    0px 4px 12px 4px rgba(0, 0, 0, 0.24);
}
.checkbox-container {
  max-height: 300px;
  position: relative;
  min-height: 100%;
  align-self: stretch;
  width: 100%;
  overflow-y: auto;
  padding: 8px 0 20px;
}
.checkbox-options {
  display: flex;
  padding: 6px 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.filter-footer {
  display: flex;
  padding: 16px 8px 8px 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: #e1315a;
  background: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  width: 100%;
  padding: 16px 12px;
}
.filter-footer:hover {
  background: #7373731f;
}
.MuiTableCell-root {
  min-width: 200px; // overriding default styles to give more width to table rows
}
.makeStyles-container-6,
.jss6 {
  min-height: 500px !important; // overriding default styles to keep checkbox filtering box fully rendered for scenarios when only one row is displayed
}
